import { Link } from "gatsby"
//import PropTypes from "prop-types"
import React from "react"

import cm from "./PostListGroupTitle.module.scss"
import { useIntl } from "react-intl"
import { getRoute } from "../utils/i18n"

interface Props {
  title: string
}

const PostListGroupTitle: React.FC<Props> = ({ title }) => {
  const intl = useIntl();
  return (
    <div className={cm.postListGroupTitle}>
        <div className="row flex-align-middle">
            <div className="columns medium-6 text-medium-right">
                <h2 className="title">{title}</h2>
            </div>
            <div className="columns medium-6 text-medium-right">
                <Link to={`/${getRoute("archive")}`} className="more">{intl.formatMessage({ id: "archive.archive" })}</Link>
            </div>
        </div>
    </div>
  )
}

export default PostListGroupTitle
