import React from "react"

import Layout from "../../components/Layout"
import SEO from "../../components/Seo"
import Hero from "../../components/Hero"
//import { Link } from "gatsby"
import PostList from "../../components/PostList"
import Categories from "../../components/Categories"
import PostListGroupTitle from "../../components/PostListGroupTitle"
import { useStaticQuery, graphql } from "gatsby"
import Gql from "../../typings/gql"
import { getLang, getRoute } from "../../utils/i18n"
import { AppContext } from "../../utils/appContext";
import { useIntl } from "react-intl"
import Pagination from "../../components/Pagination"
import { POST_PER_PAGE } from "../../constants"

interface Props {
  data: Gql.IndexPageQuery;
  pageContext: { skip: number; limit: number } & AppContext;
}

const IndexPage: React.FC<Props> =  ({ data, pageContext: { skip, categories, mainMenu, mainSiteUrl } }) => {
  const currentPage = Math.floor(skip / POST_PER_PAGE) + 1;
  const pageCount = Math.ceil(data.indexPosts.totalCount / POST_PER_PAGE);
  const intl = useIntl();
  return (
    <Layout categories={categories} mainMenu={mainMenu} mainSiteUrl={mainSiteUrl}>
      <SEO />

      <Hero posts={data.heroPosts.nodes} />

      <div className="main bg-gray">

        <Categories fullWidth />

        <PostListGroupTitle title={intl.formatMessage({ id: "common.latestnews" })} />

        <PostList page={data.indexPosts} />

        <Pagination currentPage={currentPage} pageCount={pageCount} url={`/`} />

      </div>

    </Layout>
  )
}

export const pageQuery = graphql`
query IndexPage($skip: Int!, $limit: Int!, $lang: String!) {
  heroPosts: allWordpressPost(sort: { fields: date, order: DESC }, filter: {sticky: {eq: true}, categories: {elemMatch: {acf: {language: {eq: $lang}}}}}) {
    nodes {
        slug
        title
        excerpt
        date
        categories {
            id
            acf {
              acf_slug
              display_name
            }
        }
        featured_media {
            source_url
        }
    }
  }
  indexPosts: allWordpressPost(limit: $limit, skip: $skip, sort: { fields: date, order: DESC }, filter: {categories: {elemMatch: {acf: {language: {eq: $lang}}}}}) {
    totalCount
    nodes {
        id
        title
        content
        slug
        excerpt
        modified
        date
        featured_media {
          source_url
        }
        categories {
            name
            id
            acf {
              acf_slug
              category_icon
              display_name
            }        
        }
    }
  }
}
`;

export default IndexPage
