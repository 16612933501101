//import PropTypes from "prop-types"
import React from "react"
import { Link, useStaticQuery, graphql, withPrefix } from "gatsby"

import cm from "./Hero.module.scss"
import Gql from "../typings/gql"
import { format, parseISO } from "date-fns"
import { DATE_FORMAT_STRING } from "../constants"
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel"
import "pure-react-carousel/dist/react-carousel.es.css"
import { getRoute } from "../utils/i18n"

interface Props {
  posts: Gql.IndexPageQuery["heroPosts"]["nodes"]
}

const Hero: React.FC<Props> = ({ posts: stickyPosts }) => {
  if (!stickyPosts?.length) {
    return null
  }

  return (
    <div style={{ position: "relative" }}>
      <CarouselProvider
        totalSlides={stickyPosts.length}
        naturalSlideWidth={1920}
        naturalSlideHeight={680}
      >
        <Slider>
          {stickyPosts.map((stickyPost, i) => {
            const coverImgUrl =
              stickyPost.featured_media?.source_url ||
              withPrefix("/images/cover-1.jpg")
            const mainCategory = stickyPost.categories?.[0]

            return (
              <Slide index={i} className={cm.slideHeight}>
                <div className={cm.hero}>
                  <img src={coverImgUrl} alt="" />
                  <div className={cm.heroOverlay}></div>
                  <div className={`row collapse ${cm.textContainer}`}>
                    <div className="columns">
                      {i > 0 && (
                        <ButtonBack className={cm.prev}>
                          <div title="előző">&nbsp;</div>
                        </ButtonBack>
                      )}

                      <div className={cm.slide}>
                        <div className={cm.content}>
                          <Link
                            to={`/${getRoute("article")}/${stickyPost.slug}`}
                          >
                            <h2>{stickyPost.title}</h2>
                          </Link>
                          <Link
                            to={`/${getRoute("article")}/${stickyPost.slug}`}
                          >
                            {/* <div className={cm.lead} dangerouslySetInnerHTML={{ __html: stickyPost.excerpt ?? "" }} /> */}
                            <div
                              className={cm.lead}
                              dangerouslySetInnerHTML={{
                                __html: stickyPost.excerpt ?? "",
                              }}
                            />
                          </Link>
                          <div className={cm.categoryDateInfo}>
                            <span className={cm.category}>
                              <Link to={`/${mainCategory?.acf?.acf_slug}`}>
                                {mainCategory?.acf?.display_name}
                              </Link>
                            </span>
                            <span className={cm.date}>
                              {format(
                                parseISO(
                                  stickyPost.date || new Date().toString()
                                ),
                                DATE_FORMAT_STRING
                              )}
                            </span>
                          </div>
                        </div>
                      </div>

                      {i < stickyPosts.length - 1 && (
                        <ButtonNext className={cm.next}>
                          <div title="következő">&nbsp;</div>
                        </ButtonNext>
                      )}
                    </div>
                  </div>
                </div>
              </Slide>
            )
          })}
        </Slider>
      </CarouselProvider>
    </div>
  )
}

export default Hero
